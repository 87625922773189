import React from "react";
import Grid from '@mui/material/Grid';
import styles from './commentGrid.module.css'

const CommentGridlItem = ({ id, name, comment, date }) => {
  console.log("id : " + id + " name : " + name + " comment : " + comment + " date : " + date);
  const dateFormated = new Date(date);
  var day = 0;

  if (dateFormated.getDate() + 1 <= 9) {
    day = "0" + (dateFormated.getDate() + 1);
  } else {
    day = dateFormated.getDate() + 1;
  }

  var month = 0;
  if (dateFormated.getMonth() + 1 <= 9) {
    month = "0" + (dateFormated.getMonth() + 1);
  } else {
    month = dateFormated.getMonth() + 1;
  }

  const year = dateFormated.getFullYear();

  return (
    <Grid className={styles["comment-grid__item"]} xs={12} md={5}>
      <div className={styles["comment-grid__item__header"]}>
        <span className={styles["comment-grid__item__header__name"]}>{name} </span>
        <span className={styles["comment-grid__item__header__date"]}> {day}/{month}/{year}</span>
      </div>
      <div className={styles["comment-grid__item__content"]}>
        {comment}
      </div>
    </Grid>
  );
};

export default CommentGridlItem;
