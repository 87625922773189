import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import styles from "./orderDialog.module.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const OrderDialogItem = ({ phase, orderMusic, onUpdateOrderMusic }) => {
  const [selectedValue, setSelectedValue] = useState(orderMusic ? orderMusic.id : null);

  useEffect(() => {
    setSelectedValue(orderMusic ? orderMusic.id : null);
  }, [orderMusic]);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedValue(selectedId);

    // Mettre à jour orderMusic en fonction de la valeur sélectionnée
    const selectedMusic = phase.musics.find((music) => music.id === selectedId);
    onUpdateOrderMusic(phase, selectedMusic);
  };

  return (
    <>
      <Grid item className={styles["order-dialog__item"]} xs={6}>
        <div className={styles["order-dialog__item__header"]}>{phase.name}</div>
      </Grid>
      <Grid item className={styles["order-dialog__item"]} xs={6}>
        <Select
          value={selectedValue || ""}
          onChange={handleSelectChange}
          className={styles["order-dialog__item__select"]}
        >
          <MenuItem value={null}>Aucun</MenuItem>
          {phase.musics.map((music) => (
            <MenuItem key={music.id} value={music.id}>
              {music.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </>
  );
};

export default OrderDialogItem;
