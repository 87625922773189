import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./connection.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import userRestClient from "../../../client/user";
import { toast } from "react-toastify";

export default function Connection() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleConnectionClick = async () => {
    try {
      await userRestClient.login({ email: username, password });

      navigate("/admin");
    } catch (error) {
      toast.error("C'est raté");
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <main className="main">
      <img
        className={styles["logo-connexion"]}
        src="../../assets/logo_allonge.svg"
        alt="logo"
      />
      <div className={styles["tab-connexion"]}>
        <br />
        <div className={styles["tab-connexion__line"]}>
          <FontAwesomeIcon
            icon={faUser}
            className={styles["tab-connexion__icon"]}
          />
          <input
            className={styles["tab-connexion__input"]}
            type="text"
            placeholder="Nom d'utilisateur"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <br />
        <div className={styles["tab-connexion__line"]}>
          <FontAwesomeIcon
            icon={faLock}
            className={styles["tab-connexion__icon"]}
          />
          <input
            className={styles["tab-connexion__input"]}
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <br />
        <div
          className="actionButton"
          id="connectionButton"
          onClick={handleConnectionClick}
        >
          {" "}
          Se connecter{" "}
        </div>
        <br />
        <a href={"/admin/password/forgot"}>
          <div id={styles["tab-connexion__forgot-password"]}>
            Mot de passe oublié
          </div>
        </a>
      </div>
    </main>
  );
}
