import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import CarouselItem from "./CarouselItem";
import pictureRestClient from "../../client/picture";

const HomeCarousel = () => {
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    pictureRestClient.getRandomPicture().then((data) => {
      setImages(data);
    });
  }, []);
  

  return (
    <div>
      <Carousel autoPlay infiniteLoop showThumbs={false}>
        {images.map((image) => (
            <CarouselItem key={image.id} {...image} />
        ))}
      </Carousel>
    </div>
  );
}

export default HomeCarousel;