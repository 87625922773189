import React, { useState, useEffect } from "react";
import commentRestClient from "../../../client/comment";
import styles from "./comment-management.module.css";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";

const initialRows = [];

function SimpleDialog(props) {
  const { onClose, onDelete, writerName, rowId, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleDelete = () => {
    onDelete(rowId);
  };

  return (
    <Dialog className={styles["delete-tab"]} onClose={handleClose} open={open}>
      <DialogTitle>
        Êtes-vous sûr de vouloir supprimer le commentaire de "{writerName}" ?
      </DialogTitle>
      <div className={styles["delete-tab__buttons"]}>
        <div
          className={"actionButton " + styles["delete-confirm-button"]}
          onClick={handleDelete}
        >
          Supprimer
        </div>
        <div
          className={"actionButton " + styles["delete-confirm-button"]}
          onClick={handleClose}
        >
          Retour
        </div>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ManageComment() {
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState({});
  const [open, setOpen] = useState(false);
  const [writerName, setwriterName] = useState("");
  const [rowId, setRowId] = useState(-1);

  useEffect(() => {
    commentRestClient.getComments({name: undefined, comment: undefined}).then((comments) => {
      setRows(comments);
    });
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id, name) => () => {
    setwriterName(name);
    setRowId(id);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleDeleteConfirmation = (id) => {
    setRows(rows.filter((row) => row.id !== id));
    setOpen(false);
    commentRestClient.deleteComment({ id: id });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    commentRestClient.updateComment({ id: updatedRow.id, enabled: updatedRow.enabled });
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: "name", headerName: "Auteur", flex: 15 },
    {
      field: "date",
      headerName: "Date",
      flex: 15,
      renderCell: (params) => {
        const date = new Date(params["value"]);
        const day = date.getDate();

        var month = 0;
        if (date.getMonth() + 1 <= 9) {
          month = "0" + (date.getMonth() + 1);
        } else {
          month = date.getMonth() + 1;
        }

        const year = date.getFullYear();

        return (
          <div style={{ textAlign: "justify" }}>
            {day} / {month} / {year}
          </div>
        );
      },
    },
    {
      field: "comment",
      headerName: "Commentaire",
      flex: 40,
      renderCell: (params) => {
        return <div style={{ textAlign: "justify" }}>{params.value}</div>;
      },
    },
    {
      field: "enabled",
      headerName: "Afficher",
      flex: 10,
      editable: 1,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 10,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(
              id,
              rows.find((row) => row.id === id).name
            )}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <main className="main">
      <span className="title">Gestion des Commentaires</span>
      <div className={styles["tab-comment-management"]}>
        <DataGrid
          sx={{
            width: "100%",
            bgcolor: "#FCF8EF",
            fontFamily: "argestadisplay, sans-serif",
            fontSize: "12pt",
          }}
          getRowHeight={() => "auto"}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      </div>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        onDelete={handleDeleteConfirmation}
        writerName={writerName}
        rowId={rowId}
      />
    </main>
  );
}
