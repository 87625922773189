import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import commentRestClient from '../../client/comment';
import styles from "./comment.module.css";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import CommentGrid from "../../component/commentGrid/commentGrid";

const maxLengthContent = 400; //Nb max de caractères pour le message
const maxLengthName = 30; //Nb max de caractères pour le nom / prénom

function MyForm() {
    const [formData, setFormData] = useState({
        name: '',
        content: ''
    });

    const [comments, setComments] = useState([]);

    useEffect(() => {
        commentRestClient.getComments({enabled: 1}).then((comments) => {
            setComments(comments);
        });
    }, []);

    const handleChange = (e) => {
        let { name, value } = e.target;
        switch (name) {
            case 'content':
                if (value.length >= maxLengthContent) {
                    value = value.slice(0, maxLengthContent);
                }
                break;
            case 'name':
                if (value.length >= maxLengthName) {
                    value = value.slice(0, maxLengthName);
                }
                break;
            default:
                break;
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        commentRestClient.createComment({name: formData.name, comment: formData.content}).then((comment) => {
            toast.success("Votre commentaire a bien été envoyé !");
        }).catch((error) => {
            toast.error("Une erreur est survenue lors de l'envoi du commentaire.");
        });
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        userSelect: 'none',
        width: 'fit-content',
        backgroundColor: '#A76151',
        fontSize: '15pt',
        color: 'white',
        borderRadius: '10px',
        textAlign: 'center',
        boxShadow: '5px 5px #8E624C',
        cursor: 'pointer',
        transition: 'all 0.2s',
        fontFamily: '"argestadisplay", sans-serif',
        marginTop: '15px',

        textTransform: 'none',

        '&:hover': {
            transform: 'scale(110%)',
            backgroundColor: '#A76151',
            boxShadow: '5px 5px #8E624C',
        },
        '&:active': {
            transform: 'translateY(2px)',
            backgroundColor: '#D8605A'
        }
    }));

    return (
        <main className="main">
            <span className="title">Partagez votre expérience avec Philippe Plays !</span>
            <div className={styles["tab-contact"]}>
                <form onSubmit={handleSubmit} style={{ width: 'inherit' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                sx={{
                                    backgroundColor: '#FCF8EF',
                                    borderRadius: '5px',
                                }}
                                label="Prénom"
                                variant="outlined"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                            />
                            {formData.name.length >= maxLengthName && (
                                <FormHelperText error>
                                    Limite de caractères dépassée pour le prénom ({maxLengthName} car. max)
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                    <TextField
                        sx={{
                            backgroundColor: '#FCF8EF',
                            borderRadius: '5px',
                        }}
                        label="Message"
                        variant="outlined"
                        name="content"
                        value={formData.content}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={6}
                        required
                        margin="normal"
                    />
                    {formData.content.length >= maxLengthContent && (
                        <FormHelperText error>
                            Le message dépasse la limite de caractères ({maxLengthContent} caractères maximum).
                        </FormHelperText>
                    )}
                    <ColorButton type="submit" variant="contained" color="primary" disableRipple disableTouchRipple disableFocusRipple>
                        Soumettre
                    </ColorButton>
                </form>
            </div>
            <span className='title'>Livre d'or</span>
            <CommentGrid commentsList={comments}/>
                
        </main>
    );
}

export default MyForm;