import React, { useState } from "react";
import { Link } from 'react-router-dom'
import NavItem from "./NavItem";
import styles from "./header.module.css";

const MENU_List = [
  {
    text: "Accueil",
    href: "/",
  },
  {
    text: "Marriage",
    href: "/order/wedding",
  },
  {
    text: "Enterrement",
    href: "/order/funeral",
  },
  {
    text: "Livre d'or",
    href: "/comment",
  },
  {
    text: "Contact",
    href: "/contact",
  },
];

const Header = () => {
  const [active, setActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <header className={styles.header}>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <nav className={styles.nav}>
          <Link onClick={() => setActiveIndex(0)} to={"/"} className={styles.logo}>
            <img src="../../assets/logo_allonge.svg" alt=""/>
          </Link>

        <div onClick={() => setActive(!active)} className={styles['nav__menu-bar']}>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className={`${active ? styles.active : ""} ${styles['nav__menu-list']} `}>
          {MENU_List.map((menu, index) => {
            return (
              <div onClick={() => {
                setActiveIndex(index);
                setActive(false);
              }} key={menu.text}>
                <NavItem active={activeIndex === index} {...menu} />
              </div>
            );
          })}
        </div>
      </nav>
    </header>
  );
};

export default Header;
