import React from "react";
import Grid from '@mui/material/Grid';
import CommentGridItem from "./commentGridItem";
import styles from './commentGrid.module.css'

const CommentGrid = (e) => {
  return (
    <Grid className={styles['comment-grid']} container columnGap={4} rowGap={2} spacing={2}>
      {e.commentsList.map((comment) => (
        <CommentGridItem key={comment.id} {...comment} />
      ))}
    </Grid>
  );
}

export default CommentGrid;