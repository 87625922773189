const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

async function createComment({ name, comment }) {
  try {
    const response = await fetch(`${API_BASE_URL}/comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
      },
      body: JSON.stringify({ name, comment }),
    });
    
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function getComments({ name, comment, enabled }) {
  try {

    let apiUrl = `${API_BASE_URL}/comments`;
    const queryParams = {};

    if (name) {
      queryParams.name = name;
    }

    if (comment) {
      queryParams.comment = comment;
    }

    if (enabled) {
      queryParams.enabled = enabled;
    }

    const queryString = new URLSearchParams(queryParams).toString();

    if (queryString) {
      apiUrl += `?${queryString}`;
    }
    const response = await fetch(apiUrl, {
      headers: {
        "x-api-key": API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function updateComment({ id, enabled }) {
  try {
    const token = sessionStorage.getItem("token");

    const response = await fetch(`${API_BASE_URL}/comments`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-api-key": API_KEY,
      },
      body: JSON.stringify({ id, enabled }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function deleteComment({ id }) {
  try {
    const token = sessionStorage.getItem("token");

    const response = await fetch(`${API_BASE_URL}/comments/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

const commentRestClient = {
  createComment,
  getComments,
  updateComment,
  deleteComment,
};

export default commentRestClient;
