import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styles from "./picture-management.module.css";
import pictureRestClient from "../../../client/picture";

const initialRows = [];

function SimpleDialog(props) {
  const { onClose, onDelete, writerName, rowId, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleDelete = () => {
    onDelete(rowId);
  };

  return (
    <Dialog className={styles["delete-tab"]} onClose={handleClose} open={open}>
      <DialogTitle>
        Êtes-vous sûr de vouloir supprimer le commentaire de "{writerName}" ?
      </DialogTitle>
      <div className={styles["delete-tab__buttons"]}>
        <div
          className={"actionButton " + styles["delete-confirm-button"]}
          onClick={handleDelete}
        >
          Supprimer
        </div>
        <div
          className={"actionButton " + styles["delete-confirm-button"]}
          onClick={handleClose}
        >
          Retour
        </div>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ManagePicture() {
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState({});
  const [open, setOpen] = useState(false);
  const [writerName, setwriterName] = useState("");
  const [rowId, setRowId] = useState(-1);

  //Affichage des images
  const [openOverlay, setOpenOverlay] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  //Importation d'une image
  const [selectedFile, setSelectedFile] = useState(null);
  const [pictureName, setPictureName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    pictureRestClient
      .getPictures({ name: undefined, filename: undefined })
      .then((pictures) => {
        setRows(pictures);
      });
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id, name) => () => {
    setwriterName(name);
    setRowId(id);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleDeleteConfirmation = (id) => {
    setRows(rows.filter((row) => row.id !== id));
    setOpen(false);
    pictureRestClient.deletePicture({ id: id });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log("On process row update");
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleOpenOverlay = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/pictures/play/${id}`,
        {
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }

      const imageBlob = await response.blob();
      setSelectedImage(URL.createObjectURL(imageBlob));
      setOpenOverlay(true);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const handleCloseOverlay = () => {
    setSelectedImage(null);
    setOpenOverlay(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handlePictureNameChange = (event) => {
    setPictureName(event.target.value);
  };

  const handleImportClick = () => {
    setLoading(true);
    pictureRestClient
      .uploadPicture({
        name: pictureName,
        file: selectedFile,
      })
      .then((picture) => {
        setPictureName("");
        pictureRestClient
          .getPictures({ name: undefined, filename: undefined })
          .then((pictures) => {
            setRows(pictures);
          });
      })
      .finally(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const columns = [
    { field: "name", headerName: "Nom", flex: 85, editable: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 15,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(
              id,
              rows.find((row) => row.id === id).name
            )}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<PlayArrowIcon />}
            label="Play Audio"
            className="textPrimary"
            onClick={() => handleOpenOverlay(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <main className={"main " + styles["bite"]}>
      <span className="title">Gestion des images</span>
      <div className={styles["tab-picture-management"]}>
        <DataGrid
          sx={{
            width: "100%",
            bgcolor: "#FCF8EF",
            fontFamily: "argestadisplay, sans-serif",
            fontSize: "12pt",
          }}
          getRowHeight={() => "auto"}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
        <Dialog open={openOverlay} onClose={handleCloseOverlay}>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="aperçu"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          )}
        </Dialog>
        <div
          className={"subTitle " + styles["tab-picture-management__import-tab"]}
        >
          Importer un nouveau morceau : <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TextField
                sx={{
                  backgroundColor: "#FCF8EF",
                  borderRadius: "5px",
                }}
                id="outlined-basic"
                label="Titre"
                variant="outlined"
                onChange={handlePictureNameChange}
                value={pictureName}
              />
            </Grid>
            <Grid item xs={2}>
              <input
                onChange={handleFileChange}
                value={selectedFile?.fileName}
                type="file"
                label="Fichier"
              />
            </Grid>
            <Grid item xs={7}>
              {loading ? <CircularProgress /> : ""}
            </Grid>
          </Grid>
          <div
            onClick={handleImportClick}
            className={
              "actionButton " +
              styles["tab-picture-management__import-tab__import-button"]
            }
          >
            Importer
          </div>
        </div>
      </div>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        onDelete={handleDeleteConfirmation}
        writerName={writerName}
        rowId={rowId}
      />
    </main>
  );
}
