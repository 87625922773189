import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import userRestClient from "../../../client/user";
import styles from "./change-password.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

export default function ChangePassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const handleChangePasswordClick = async () => {
    try {
      if (password !== passwordConfirm) {
        toast.error("Les mots de passe ne correspondent pas");
        return;
      }

      await userRestClient.changePassword({ password: password, token: token });

      navigate("/admin/connection");
    } catch (error) {
      toast.error("Un problème est survenu");
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmChange = (event) => {
    setPasswordConfirm(event.target.value);
  };

  return (
    <main className="main">
      <img
        className={styles["logo-connexion"]}
        src="../../assets/logo_allonge.svg"
        alt="logo"
      />
      <div className={styles["tab_change-password"]}>
        <br />
        <span className="subTitle" style={{ textAlign: "center" }}>
          Changez votre mot de passe
        </span>
        <br />
        <div className={styles["tab_change-password__line"]}>
          <FontAwesomeIcon
            icon={faLock}
            className={styles["tab_change-password__icon"]}
          />
          <input
            className={styles["tab_change-password__input"]}
            type="text"
            placeholder="Mot de passe"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <br />
        <div className={styles["tab_change-password__line"]}>
          <FontAwesomeIcon
            icon={faLock}
            className={styles["tab_change-password__icon"]}
          />
          <input
            className={styles["tab_change-password__input"]}
            type="text"
            placeholder="Confirmation"
            value={passwordConfirm}
            onChange={handlePasswordConfirmChange}
          />
        </div>
        <br />
        <div
          className="actionButton"
          id="connectionButton"
          onClick={handleChangePasswordClick}
        >
          {" "}
          Changer le mot de passe{" "}
        </div>
        <br />
        <a href={"/admin/connection"}>
          <div id={styles["tab_change-password__back"]}>Retour</div>
        </a>
      </div>
    </main>
  );
}
