import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styles from './footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.contacts}>
        <h3>Contact</h3>
        <p><FontAwesomeIcon icon={faPhone} /><a href='tel:06 17 30 39 83'>06 17 30 39 83</a> </p>
        <p><FontAwesomeIcon icon={faEnvelope}/><a href='mailto:phil.plessis-musique@orange.fr'>phil.plessis-musique@orange.fr</a></p>
      </div>
      {/* <div className={styles.legal}>
        <h3>Mentions Légales</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
      </div> */}
    </footer>
  );
};

export default Footer;
