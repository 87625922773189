const FormData = require("form-data");

// Définissez l'URL de base de votre API et la clé API
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

async function uploadMusic({ name, type, music, lyrics }) {
  try {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    formData.append("music", music);
    if (lyrics) {
      formData.append("lyrics", lyrics);
    }

    // Créez les options de la requête Fetch
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
      body: formData,
    };

    const response = await fetch(`${API_BASE_URL}/musics`, requestOptions);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function getMusics({ name, type }) {
  try {
    // Créez les options de la requête Fetch avec les paramètres de la requête

    let apiUrl = `${API_BASE_URL}/musics`;
    const queryParams = {};

    if (name) {
      queryParams.name = name;
    }

    if (type) {
      queryParams.type = type;
    }

    const queryString = new URLSearchParams(queryParams).toString();

    if (queryString) {
      apiUrl += `?${queryString}`;
    }

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
    };

    const response = await fetch(apiUrl, requestOptions);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function getMusicById({ musicId }) {
  try {
    // Créez les options de la requête Fetch
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
    };

    const response = await fetch(
      `${API_BASE_URL}/musics/${musicId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function updateMusic({
  id,
  name,
  type,
  enabled,
  weddingPhase,
  funeralPhase,
}) {
  try {
    // Créez les options de la requête Fetch
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        name,
        type,
        enabled,
        weddingPhase,
        funeralPhase,
      }),
    };

    const response = await fetch(`${API_BASE_URL}/musics`, requestOptions);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function deleteMusic({ id }) {
  try {
    // Créez les options de la requête Fetch
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
    };

    const response = await fetch(
      `${API_BASE_URL}/musics/${id}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function getPhases({ type }) {
  try {
    // Créez les options de la requête Fetch avec les paramètres de la requête

    let apiUrl = `${API_BASE_URL}/phases`;
    const queryParams = {};

    if (type) {
      queryParams.type = type;
    }

    const queryString = new URLSearchParams(queryParams).toString();

    if (queryString) {
      apiUrl += `?${queryString}`;
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": API_KEY,
      },
    };

    const response = await fetch(apiUrl, requestOptions);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

const musicRestClient = {
  uploadMusic,
  getMusics,
  getMusicById,
  updateMusic,
  deleteMusic,
  getPhases,
};

export default musicRestClient;
