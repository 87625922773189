import React from "react";
import Grid from '@mui/material/Grid';
import styles from './orderGrid.module.css'
import OrderGridItemContent from "./orderGridItemContent";

const OrderGridlItem = ({ musics, id, name, onMusicSelected, playlist }) => {

    const handleCheckBox = (musicId) => {
        onMusicSelected(id, musicId);
    }

    return (
        <>
            <Grid item xs={12} sm={6} lg={4} className={styles["order-grid-item__tab"]}>
                <Grid>
                    <Grid item xs={12} className={styles["order-grid__phase"]}>
                        {name}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} className={styles["order-grid-item__music-content"]}>
                            {musics.map((music) => (
                                <OrderGridItemContent key={music.id} music={music} onCheckBox={handleCheckBox} playlist={playlist} phaseId={id}/>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
};

export default OrderGridlItem;
