import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import userRestClient from "../../../client/user";
import styles from "./forgot-password.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");

  const handleConnectionClick = async () => {
    try {
      await userRestClient.resetPassword({ email: username });
      toast.success("Un email a été envoyé à l'adresse indiquée si elle existe");
      navigate("/admin/connection");
    } catch (error) {
      toast.error("Un problème est survenu");
    }
  }

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  }

  return (
    <main className="main">
      <img
        className={styles["logo-connexion"]}
        src="../../assets/logo_allonge.svg"
        alt="logo"
      />
      <div className={styles["tab-forgot-password"]}>
        <br />
        <span className="subTitle" style={{ textAlign: "center" }}>
          Récupérez votre mot de passe
        </span>
        <br />
        <div className={styles["tab-forgot-password__line"]}>
          <FontAwesomeIcon
            icon={faUser}
            className={styles["tab-forgot-password__icon"]}
          />
          <input
            className={styles["tab-forgot-password__input"]}
            type="text"
            placeholder="Nom d'utilisateur" value={username} onChange={handleUsernameChange}
          />
        </div>
        <br />
        <div className="actionButton" id="connectionButton" onClick={handleConnectionClick}>
          {" "}
          Valider{" "}
        </div>
        <br />
        <a href={"/admin/connection"}>
          <div id={styles["tab-forgot-password__back"]}>Retour</div>
        </a>
      </div>
    </main>
  );
}
