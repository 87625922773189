const apiUrl = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

async function signUp({ email, password }) {
  try {
    const response = await fetch(`${apiUrl}/sign-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error('Erreur lors de la requête');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

async function login({ email, password }) {
  try {
    const response = await fetch(`${apiUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error('Mot de passe ou email incorrect');
    }

    const data = await response.json();
    sessionStorage.setItem('token', data.token);
    return data;
  } catch (error) {
    throw error;
  }
}

async function resetPassword({ email}) {
  try {
    const response = await fetch(`${apiUrl}/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify({ email}),
    });

    if (!response.ok) {
      throw new Error('Erreur lors de la requête');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

async function changePassword({ password, token }) {
  try {
    const response = await fetch(`${apiUrl}/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify({ password, token }),
    });

    if (!response.ok) {
      throw new Error('Erreur lors de la requête');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

const userRestClient = {
  signUp,
  login,
  resetPassword,
  changePassword
};

export default userRestClient;
