import React from "react";
import { Link } from 'react-router-dom'
import styles from "./header.module.css";

const NavItem = ({ href, text, active }) => {
  return (
    <Link className={`${styles.nav__link} ${active ? styles.active : ""}`}  to={href}>{text}</Link>
  );
};

export default NavItem;
