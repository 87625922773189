import React from "react";
import { Outlet } from "react-router-dom";
import jwtDecode from "jwt-decode";

const ProtectedRoute = () => {
  const useAuth = () => {
    const token = sessionStorage.getItem("token");
    if (token) {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000;

        if (Date.now() > expirationTime) {
            sessionStorage.removeItem('token');
            return false;
        }
        return true;
    }
    return false;
  };

  const navigate = () => {
    window.location.href = "/";
  };

  const isAuth = useAuth();
  return isAuth ? <Outlet /> : navigate();
};

export default ProtectedRoute;
