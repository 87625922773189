import React from "react";
import HomeCarousel from "../../component/imageSlider/HomeCarousel";
import styles from "./home.module.css";

export default function Home() {
  return (
    <main className={styles.main}>
      <div className={styles["hook__container"]}>
        <p className={styles["hook__text"]}>
          « Avec vous en toutes circonstances… »
        </p>
      </div>

      <div className={styles["content__container"]}>
        <div
          className={`${styles["content__box"]} ${styles["content__description"]}`}
        >
          <img src="assets/phiphi_le_bg.jpg" alt="Le bg" />
          <div className={styles["content__description__text"]}>
            <h2>Présentation</h2>
            <p>
              Vous souhaitez faire de votre célébration un moment unique.
              <br />
              Qu’elle soit joyeuse ou triste, je me mets à votre disposition
              afin de l’accompagner musicalement. <br /> En effet, ma formation
              et mes expériences musicales variées (organiste médaillé au
              conservatoire, claviériste, accordéoniste, chef de chœur…) et mon
              implication en église depuis mon plus jeune âge, me permettent de
              maîtriser le déroulement des célébrations et d’interpréter
              parfaitement les œuvres liturgiques, classiques ou modernes selon
              vos souhaits.
              <br /> Plus qu'un musicien, je suis avant tout une personne à
              l’écoute, avec l’envie de partager sa passion et de vous
              accompagner dans la joie comme dans la peine...
              <br /><br /> Je me déplace dans un rayon de 25km autour d’Annœullin.
            </p>
          </div>
        </div>
        <div className={styles["content__box"]}>
          <HomeCarousel />
        </div>
      </div>
    </main>
  );
}
