import Grid from '@mui/material/Grid';
import OrderGridItem from "./orderGridItem";
import React, { useState, useEffect } from "react";
import musicRestClient from "../../client/music";

const OrderGrid = ({type, addToPlaylist, playlist}) => {
  const [phasesList, setphasesList] = useState([]);

  const handleMusicSelected = (phaseId, musicId) => {
    addToPlaylist(phaseId, musicId);
  };

  useEffect(() => {
    musicRestClient.getPhases({ type: type }).then((phasesList) => {
      setphasesList(phasesList);
    });
  }, [type]);

  return (
    <Grid container columnSpacing={0} spacing={2}>
      {phasesList.map((phase) => (
          <OrderGridItem key={phase.id} id={phase.id} name={phase.name} musics={phase.musics} onMusicSelected={handleMusicSelected} playlist={playlist}/>
      ))}
    </Grid>
  );
}

export default OrderGrid;

