import React, { useState, useEffect } from "react";
import styles from "./music-management.module.css";
import { DataGrid } from "@mui/x-data-grid";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import musicRestClient from "../../../client/music";
import LyricsDialog from "../../../component/lyricsDialog/LyricsDialog";

let initialRows = [];
let currentAudioElement;

function SimpleDialog(props) {
  const { onClose, onDelete, songName, rowId, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleDelete = () => {
    onDelete(rowId);
  };

  return (
    <Dialog className={styles["delete-tab"]} onClose={handleClose} open={open}>
      <DialogTitle>
        Êtes-vous sûr de vouloir supprimer "{songName}" ?
      </DialogTitle>
      <div className={styles["delete-tab__buttons"]}>
        <div
          className={"actionButton " + styles["delete-confirm-button"]}
          onClick={handleDelete}
        >
          Supprimer
        </div>
        <div
          className={"actionButton " + styles["delete-confirm-button"]}
          onClick={handleClose}
        >
          Retour
        </div>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ManageMusic() {
  const [weddingPhaseList, setWeddingPhaseList] = useState([]);
  const [funeralPhaseList, setFuneralPhaseList] = useState([]);
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState({});
  const [open, setOpen] = useState(false);
  const [songName, setSongName] = useState("");
  const [rowId, setRowId] = useState(-1);
  const [editingRows, setEditingRows] = useState({});

  const [selectedMusic, setSelectedMusic] = useState(null);
  const [selectedLyrics, setSelectedLyrics] = useState(null);
  const [musicName, setMusicName] = useState("");
  const [loading, setLoading] = useState(false);

  //Affichage paroles
  const [openLyricsDialog, setOpenLyricsDialog] = useState(false);
  const [selectedMusicId, setSelectedMusicId] = useState(null);

  useEffect(() => {
    musicRestClient.getPhases({ type: "Funeral" }).then((phasesFuneral) => {
      setFuneralPhaseList(phasesFuneral.sort());

      musicRestClient.getPhases({ type: "Wedding" }).then((phasesWedding) => {
        setWeddingPhaseList(phasesWedding.sort());
        musicRestClient
          .getMusics({ name: undefined, type: undefined })
          .then((musics) => {
            musics.forEach((music) => {
              music.funeralPhase = music.funeralPhase.map((phase) => {
                return phasesFuneral.find(
                  (funeralPhase) => funeralPhase.id === phase.id
                );
              });
              music.weddingPhase = music.weddingPhase.map((phase) => {
                return phasesWedding.find(
                  (weddingPhase) => weddingPhase.id === phase.id
                );
              });
            });
            initialRows = musics;
            setRows(musics);
          });
      });
    });
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const updatedEditingRows = { ...editingRows };
    updatedEditingRows[id] = { ...rows.find((row) => row.id === id) };
    setEditingRows(updatedEditingRows);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    const updatedRows = rows.map((row) =>
      row.id === id ? editingRows[id] : row
    );
    setRows(updatedRows);

    const updatedEditingRows = { ...editingRows };
    delete updatedEditingRows[id];
    setEditingRows(updatedEditingRows);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const updatedRows = rows.map((row) =>
      row.id === id ? editingRows[id] : row
    );
    setRows(updatedRows);

    // Supprimez la copie en cours d'édition
    const updatedEditingRows = { ...editingRows };
    delete updatedEditingRows[id];
    setEditingRows(updatedEditingRows);
  };

  const processRowUpdate = (newRow) => {
    console.log(newRow);
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    musicRestClient.updateMusic({
      id: updatedRow.id,
      name: updatedRow.name,
      type: updatedRow.type,
      funeralPhase: updatedRow.funeralPhase,
      weddingPhase: updatedRow.weddingPhase,
      enabled: updatedRow.enabled,
    });
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleDeleteClick = (id, name) => () => {
    setSongName(name);
    setRowId(id);
    setOpen(true);
  };

  const handlePlayAudio = async (id) => {
    try {
      if (currentAudioElement) {
        // Si un élément audio est déjà en cours de lecture
        if (currentAudioElement.dataset.id === id) {
          // Si le bouton "Play Audio" a été cliqué sur la même ligne
          currentAudioElement.pause();
          currentAudioElement = undefined; // Réinitialisez la référence
          return; // Arrêtez la fonction ici
        } else {
          // Si le bouton "Play Audio" a été cliqué sur une autre ligne
          currentAudioElement.pause();
          currentAudioElement = undefined; // Réinitialisez la référence
        }
      }
  
      // Créez un nouvel élément audio HTML pour le nouvel audio
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/musics/play/${id}`,
        {
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
  
      if (response.ok) {
        const audioBlob = await response.blob();
        const audioObjectURL = URL.createObjectURL(audioBlob);
        const audioElement = new Audio(audioObjectURL);
        audioElement.dataset.id = id; // Ajoutez l'ID de la ligne comme attribut personnalisé
  
        // Démarrez la lecture de l'audio
        audioElement.play();
        currentAudioElement = audioElement; // Mettez à jour la référence
      } else {
        console.error("Échec de la récupération du fichier audio.");
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de la manipulation de l'audio :", error);
    }
  };

  const handleOpenLyricsDialog = (musicId) => {
    setSelectedMusicId(musicId);
    setOpenLyricsDialog(true);
  };

  const handleCloseLyricsDialog = () => {
    console.log("close");
    setSelectedMusicId(null);
    setOpenLyricsDialog(false);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleDeleteConfirmation = (id) => {
    setRows(rows.filter((row) => row.id !== id));
    setOpen(false);
    musicRestClient.deleteMusic({ id: id });
  };

  const handleMusicFileChange = (event) => {
    setSelectedMusic(event.target.files[0]);
  };

  const handleLyricsFileChange = (event) => {
    setSelectedLyrics(event.target.files[0]);
  };

  const handleMusicNameChange = (event) => {
    setMusicName(event.target.value);
  };

  const handleImportClick = () => {
    setLoading(true);
    musicRestClient
      .uploadMusic({
        name: musicName,
        music: selectedMusic,
        lyrics: selectedLyrics,
        type: "Mariage + Funérailles",
      })
      .then((music) => {
        setMusicName("");
        musicRestClient.getPhases({ type: "Funeral" }).then((phasesFuneral) => {
          setFuneralPhaseList(phasesFuneral);

          musicRestClient
            .getPhases({ type: "Wedding" })
            .then((phasesWedding) => {
              setWeddingPhaseList(phasesWedding);
              musicRestClient
                .getMusics({ name: undefined, type: undefined })
                .then((musics) => {
                  musics.forEach((music) => {
                    music.funeralPhase = music.funeralPhase.map((phase) => {
                      return phasesFuneral.find(
                        (funeralPhase) => funeralPhase.id === phase.id
                      );
                    });
                    music.weddingPhase = music.weddingPhase.map((phase) => {
                      return phasesWedding.find(
                        (weddingPhase) => weddingPhase.id === phase.id
                      );
                    });
                  });
                  initialRows = musics;
                  setRows(musics);
                });
            });
        });
      })
      .finally(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Titre",
      flex: 15,
      editable: 1,
    },
    {
      field: "type",
      headerName: "Catégorie",
      flex: 16,
      align: "center",
      headerAlign: "center",
      editable: 1,
      type: "singleSelect",
      valueOptions: ["Mariage", "Funérailles", "Mariage + Funérailles"],
    },
    {
      field: "funeralPhase",
      headerName: "Phase Funérailles",
      headerAlign: "center",
      align: "center",
      flex: 15,
      renderCell: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        const handleOptionChange = (event) => {
          const funeralPhase = event.target.value;
          const updatedEditingRows = { ...editingRows };
          updatedEditingRows[params.id].funeralPhase = funeralPhase;
          setEditingRows(updatedEditingRows);
        };

        if (isInEditMode) {
          return (
            <div style={{ whiteSpace: "pre-line" }}>
              <Select
                multiple
                value={editingRows[params.id]?.funeralPhase || []}
                onChange={handleOptionChange}
                style={{
                  position: "relative",
                  width: params.colDef.computedWidth + "px",
                }}
              >
                {funeralPhaseList.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option}
                    style={{
                      backgroundColor: (
                        editingRows[params.id]?.funeralPhase || []
                      ).includes(option)
                        ? "#D9B56A" // Couleur personnalisée pour les éléments sélectionnés
                        : "#FFFFFF", // Couleur de fond par défaut pour les éléments non sélectionnés
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          );
        } else {
          var optionsToDisp = "";
          if (params.value[0]) {
            optionsToDisp = params.value[0].name;
          }
          for (var i = 1; i < params.value.length; i++) {
            optionsToDisp = optionsToDisp + ",\n" + params.value[i].name;
          }
          return <div style={{ whiteSpace: "pre-line" }}>{optionsToDisp}</div>;
        }
      },
    },
    {
      field: "weddingPhase",
      headerName: "Phase Mariage",
      headerAlign: "center",
      align: "center",
      flex: 15,
      renderCell: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        const handleOptionChange = (event) => {
          const weddingPhase = event.target.value;
          const updatedEditingRows = { ...editingRows };
          updatedEditingRows[params.id].weddingPhase = weddingPhase;
          setEditingRows(updatedEditingRows);
        };

        if (isInEditMode) {
          return (
            <div style={{ whiteSpace: "pre-line" }}>
              <Select
                multiple
                value={editingRows[params.id]?.weddingPhase || []}
                onChange={handleOptionChange}
                style={{
                  position: "relative",
                  width: params.colDef.computedWidth + "px",
                }}
              >
                {weddingPhaseList.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option}
                    style={{
                      backgroundColor: (
                        editingRows[params.id]?.weddingPhase || []
                      ).includes(option)
                        ? "#D9B56A" // Couleur personnalisée pour les éléments sélectionnés
                        : "#FFFFFF", // Couleur de fond par défaut pour les éléments non sélectionnés
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          );
        } else {
          var optionsToDisp = "";
          if (params.value[0]) {
            optionsToDisp = params.value[0].name;
          }
          for (var i = 1; i < params.value.length; i++) {
            optionsToDisp = optionsToDisp + ",\n" + params.value[i].name;
          }
          return <div style={{ whiteSpace: "pre-line" }}>{optionsToDisp}</div>;
        }
      },
    },
    {
      field: "enabled",
      headerName: "Afficher",
      flex: 10,
      editable: 1,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 10,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(
              id,
              rows.find((row) => row.id === id).name
            )}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<PlayArrowIcon />}
            label="Play Audio"
            className="textPrimary"
            onClick={() => handlePlayAudio(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Play Audio"
            className="textPrimary"
            onClick={() => handleOpenLyricsDialog(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <main className="main">
      <span className="title">Gestion des extraits musicaux</span>
      <div className={styles["tab-music-management"]}>
        <DataGrid
          sx={{
            width: "100%",
            bgcolor: "#FCF8EF",
            fontFamily: "argestadisplay, sans-serif",
            fontSize: "12pt",
          }}
          getRowHeight={() => "auto"}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
        <br />
        <div
          className={"subTitle " + styles["tab-music-management__import-tab"]}
        >
          Importer un nouveau morceau : <br />
          <br />
          <Grid container spacing={4}>

          <Grid item xs={2}>
              Titre
            </Grid>
            <Grid item xs={2}>
              Morceau
            </Grid>
            <Grid item xs={2}>
              Paroles
            </Grid>
            <Grid item xs={6}>
            </Grid>

            <Grid item xs={2}>
              <TextField
                sx={{
                  backgroundColor: "#FCF8EF",
                  borderRadius: "5px",
                }}
                id="outlined-basic"
                label="Titre"
                variant="outlined"
                onChange={handleMusicNameChange}
                value={musicName}
              />
            </Grid>
            <Grid item xs={2}>
              <input
                onChange={handleMusicFileChange}
                value={selectedMusic?.fileName}
                type="file"
                label="Fichier"
                accept="audio/mpeg, audio/mp3, audio/wave, audio/vnd.wave, audio/wav, audio/x-pn-wav, audio/x-wav"
              />
            </Grid>
            <Grid item xs={2}>
              <input
                onChange={handleLyricsFileChange}
                value={selectedLyrics?.fileName}
                type="file"
                label="Fichier"
                accept="application/pdf"
              />
            </Grid>
            <Grid item xs={6}>
              {loading ? <CircularProgress /> : ""}
            </Grid>
          </Grid>
          <div
            onClick={handleImportClick}
            className={
              "actionButton " +
              styles["tab-music-management__import-tab__import-button"]
            }
          >
            Importer
          </div>
        </div>
      </div>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        onDelete={handleDeleteConfirmation}
        songName={songName}
        rowId={rowId}
      />
      <LyricsDialog open={openLyricsDialog} onClose={handleCloseLyricsDialog} id={selectedMusicId} />
      
    </main>
  );
}
