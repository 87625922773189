const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

async function createOrder({
  name,
  type,
  mail,
  phone,
  city,
  date,
  comment,
  playlist,
}) {
  try {
    const response = await fetch(`${API_BASE_URL}/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
      },
      body: JSON.stringify({
        name,
        type,
        mail,
        phone,
        city,
        date,
        comment,
        playlist,
      }),
    });
    
    if (!response.ok) {
      throw new Error("Failed to create order");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function getOrders({ name, type, startingDate, endingDate }) {
  try {
    let apiUrl = `${API_BASE_URL}/orders`;
    const queryParams = {};

    if (name) {
      queryParams.name = name;
    }

    if (type) {
      queryParams.type = type;
    }

    if (startingDate) {
      queryParams.startingDate = startingDate;
    }

    if (endingDate) {
      queryParams.endingDate = endingDate;
    }

    const queryString = new URLSearchParams(queryParams).toString();

    if (queryString) {
      apiUrl += `?${queryString}`;
    }
    
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
    });
    
    if (!response.ok) {
      throw new Error("Failed to fetch orders");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function getOrderById({ orderId }) {
  try {
    const response = await fetch(`${API_BASE_URL}/orders/${orderId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
    });
    
    if (!response.ok) {
      throw new Error("Failed to fetch order by ID");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function updateOrder({
  id,
  name,
  type,
  mail,
  phone,
  city,
  date,
  comment,
  musics,
  status,
  playlist
}) {
  try {
    const response = await fetch(`${API_BASE_URL}/orders`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
      body: JSON.stringify({
        id,
        name,
        type,
        mail,
        phone,
        city,
        date,
        comment,
        musics,
        status,
        playlist
      }),
    });
    
    if (!response.ok) {
      throw new Error("Failed to update order");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function deleteOrder(orderId) {
  try {
    const response = await fetch(`${API_BASE_URL}/orders/${orderId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
    });
    
    if (!response.ok) {
      throw new Error("Failed to delete order");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

const orderRestClient = {
  createOrder,
  getOrders,
  getOrderById,
  updateOrder,
  deleteOrder,
};

export default orderRestClient;
