import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WeddingOrder from "./app/order/wedding/weeding";
import Footer from "./component/footer/footer";
import Header from "./component/header/header";
import OtherOrder from "./app/order/other/other";
import FuneralOrder from "./app/order/funeral/funeral";
import ChangePassword from "./app/admin/change-password/changePassword";
import Connection from "./app/admin/connection/connection";
import ForgotPassword from "./app/admin/forgot-password/forgot-password";
import ManageMusic from "./app/admin/music-management/music-management";
import ManageComment from "./app/admin/comment-management/comment-management";
import ManageOrder from "./app/admin/order-management/order-management";
import Contact from "./app/contact/contact";
import Comment from "./app/comment/comment";
import Home from "./app/home/home";
import Error from "./component/error/error";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeAdmin from "./app/admin/home/home";
import ProtectedRoute from "./component/auth/ProtectedRoute";
import ManagePicture from "./app/admin/picture-management/picture-management";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route element={<Home />} path="/" />
          <Route path="/order">
            <Route element={<WeddingOrder />} path="wedding" />
            <Route element={<OtherOrder />} path="other" />
            <Route element={<FuneralOrder />} path="funeral" />
          </Route>
          <Route path="/admin">
            
            <Route element={<ChangePassword />} path="password/change" />
            <Route element={<Connection />} path="connection" />
            <Route element={<ForgotPassword />} path="password/forgot" />
            <Route element={<ProtectedRoute />}>
              <Route element={<ManageOrder />} path="order" />
              <Route element={<HomeAdmin />} path="" />
              <Route element={<ManageMusic />} path="music" />
              <Route element={<ManageComment />} path="comment" />
              <Route element={<ManagePicture />} path="picture" />
            </Route>
          </Route>
          <Route element={<Contact />} path="/contact" />
          <Route element={<Comment />} path="/comment" />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
