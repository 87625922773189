const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

async function sendContactMessage({ firstName, lastName, email, phoneNumber, content, subject }) {
    try {
      const response = await fetch(`${API_BASE_URL}/contacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
        body: JSON.stringify({ 
          firstName, 
          lastName, 
          email, 
          phoneNumber, 
          content, 
          subject 
        }),
      });
      
      if (!response.ok) {
        throw new Error("La réponse du réseau n'était pas correcte");
      }
  
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

export default sendContactMessage;