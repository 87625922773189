import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const LyricsDialog = ({ open, onClose, id }) => {
  const [pdfContent, setPdfContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchPdfContent = async () => {
      try {
        if (id) {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/musics/${id}/lyrics`,
            {
              headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
              },
            }
          );
          if (response.ok) {
            const content = await response.blob();
            const pdfUrl = URL.createObjectURL(content);
            setPdfContent(pdfUrl);
            setIsLoading(false);
          } else {
            setError(true);
            setIsLoading(false);
            console.error(
              "Erreur lors de la récupération du PDF depuis l'API"
            );
          }
        }
      } catch (error) {
        setError(true);
        setIsLoading(false);
        console.error("Erreur lors de la récupération du PDF:", error);
      }
    };

    if (open) {
      fetchPdfContent();
    } else {
      // Réinitialiser l'état lorsque la boîte de dialogue est fermée
      setError(false);
      setPdfContent(null);
      setIsLoading(true);
    }
  }, [open, id]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent style={{ height: error ? "10vh" : "80vh" }}>
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          {isLoading ? (
            <p>Chargement des paroles en cours...</p>
          ) : (
            <>
              {error ? (
                <p>Les paroles ne sont pas disponibles pour ce morceau</p>
              ) : (
                <iframe
                  src={pdfContent}
                  style={{ flex: 1 }}
                  title="PDF Viewer"
                ></iframe>
              )}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LyricsDialog;
