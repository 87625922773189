import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import musicRestClient from "../../client/music";
import orderRestClient from "../../client/order";
import styles from "./orderDialog.module.css";
import OrderDialogItem from "./orderDialogItem";

const regexMail = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
const regexPhone = /^((\+)33|0)[1-9](\d{2}){4}$/;

let flagMail = false;
let flagPhone = false;

const OrderDialog = ({ onClose, open, order, refreshRows }) => {
  let editableContent = { ...order };

  const [mail, setMail] = useState(order.mail);
  const [phone, setPhone] = useState(order.phone);
  const [date, setDate] = useState(order.date);
  const [city, setCity] = useState(order.city);

  const [phasesList, setphasesList] = useState([]);
  useEffect(() => {
    musicRestClient.getPhases({ type: order.type }).then((phasesList) => {
      setphasesList(phasesList);
    });
  }, [order.type]);

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleContentChange = (event) => {
    switch (event.target.id) {
      case "phoneNumber":
        if (event.target.value.match(regexPhone)) {
          flagPhone = false;
        } else {
          flagPhone = true;
        }
        setPhone(event.target.value);
        break;
      case "mailAddress":
        if (event.target.value.match(regexMail)) {
          flagMail = false;
        } else {
          flagMail = true;
        }
        setMail(event.target.value);
        break;
      case "date":
        setDate(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        break;
      default:
        break;
    }
  };
  const handleValidationClick = () => {
    if (!flagPhone && !flagMail) {
      editableContent.mail = mail;
      editableContent.phone = phone;
      editableContent.date = new Date(date).toISOString();
      editableContent.city = city;

      orderRestClient.updateOrder(editableContent).then((result) => {
        orderRestClient
          .getOrderById({orderId: editableContent.id})
          .then((updatedOrder) => {
            refreshRows(updatedOrder);
            onClose(false);
          });
      });
      return;
    } else {
      toast.error("Veuillez vérifier les champs en rouge");
    }
  };

  const handleCancelClick = () => {
    editableContent = { ...order };
    onClose(false);
  };

  const onUpdateOrderMusic = (phase, selectedMusic) => {
    const newPlaylist = [...editableContent.playlist];
    const orderMusic = newPlaylist.find(
      (playlist) => playlist.phase.id === phase.id
    );

    if (orderMusic) {
      if (!selectedMusic) {
        newPlaylist.splice(newPlaylist.indexOf(orderMusic), 1);
      } else {
        orderMusic.music = selectedMusic;
      }
    } else {
      newPlaylist.push({ phase, music: selectedMusic });
    }

    editableContent.playlist = newPlaylist;
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Détail de la commande de "{order.name}" :</DialogTitle>
      <div className={styles["order-dialog__contact"]}>
        <div className={styles["order-dialog__title"]}> Contact </div>
        <div className={styles["order-dialog__line"]}>
          <span className={styles["order-dialog__line__sub"]}>
            <FontAwesomeIcon className={styles["icon"]} icon={faPhone} />
            <TextField
              fullWidth
              variant="outlined"
              label="Téléphone client"
              id="phoneNumber"
              value={phone}
              onChange={handleContentChange}
              autoFocus
            />
            {flagPhone && (
              <FormHelperText error>
                Format du numéro de téléphone (format cible : 0xxxxxxxxx)
              </FormHelperText>
            )}
          </span>
          <span className={styles["order-dialog__line__sub"]}>
            <FontAwesomeIcon className={styles["icon"]} icon={faEnvelope} />

            <TextField
              fullWidth
              variant="outlined"
              label="Mail client"
              id="mailAddress"
              value={mail}
              onChange={handleContentChange}
              autoFocus
            />

            {flagMail && (
              <FormHelperText error>
                Format de l'adresse mail invalide (format cible : xxx@yyy.zz)
              </FormHelperText>
            )}
          </span>
        </div>
      </div>
      <div className={styles["order-dialog__informations"]}>
        <div className={styles["order-dialog__title"]}>
          {" "}
          Informations de prestation{" "}
        </div>
        <div className={styles["order-dialog__line"]}>
          <span className={styles["order-dialog__line__sub"]}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                format="DD/MM/YYYY"
                value={dayjs(date)}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          </span>
          <span className={styles["order-dialog__line__sub"]}>
            <TextField
              fullWidth
              variant="outlined"
              label="Lieu de la prestation"
              id="city"
              value={city}
              onChange={handleContentChange}
              autoFocus
            />
          </span>
        </div>
      </div>
      <div className={styles["order-dialog__playlist"]}>
        <div className={styles["order-dialog__title"]}>Choix des morceaux</div>
        <div className={styles["order-dialog__playlist__tab"]}>
          <Grid
            sx={{ width: "50%", textAlign: "center" }}
            className={styles["order-dialog__playlist-grid"]}
            container
            spacing={2}
          >
            <Grid
              item
              className={styles["order-dialog__item"] + " " + styles["header"]}
              xs={6}
            >
              Phase
            </Grid>
            <Grid
              item
              className={styles["order-dialog__item"] + " " + styles["header"]}
              xs={6}
            >
              Morceau
            </Grid>
            {phasesList.map((phase) => (
              <OrderDialogItem
                key={phase.id}
                onUpdateOrderMusic={onUpdateOrderMusic}
                phase={phase}
                orderMusic={
                  editableContent.playlist.find(
                    (playlist) => playlist.phase.id === phase.id
                  )?.music
                }
              />
            ))}
          </Grid>
        </div>
      </div>
      <div className={styles["order-dialog__buttons"]}>
        <div
          className={"actionButton " + styles["order-dialog-button"]}
          onClick={handleValidationClick}
        >
          Valider
        </div>
        <div
          className={"actionButton " + styles["order-dialog-button"]}
          onClick={handleCancelClick}
        >
          Retour
        </div>
      </div>
    </Dialog>
  );
};

export default OrderDialog;
