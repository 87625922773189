import React, { useState } from "react";
import styles from "./orderGrid.module.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Grid from "@mui/material/Grid";
import LyricsDialog from "../../component/lyricsDialog/LyricsDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Radio } from "@mui/material";

let currentAudioElement;

const handlePlayAudio = async (id) => {
  try {
    if (currentAudioElement) {
      // Si un élément audio est déjà en cours de lecture
      if (currentAudioElement.dataset.id === id) {
        // Si le bouton "Play Audio" a été cliqué sur la même ligne
        currentAudioElement.pause();
        currentAudioElement = undefined; // Réinitialisez la référence
        return; // Arrêtez la fonction ici
      } else {
        // Si le bouton "Play Audio" a été cliqué sur une autre ligne
        currentAudioElement.pause();
        currentAudioElement = undefined; // Réinitialisez la référence
      }
    }

    // Créez un nouvel élément audio HTML pour le nouvel audio
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/musics/play/${id}`,
      {
        headers: {
          "x-api-key": `${process.env.REACT_APP_API_KEY}`,
        },
      }
    );

    if (response.ok) {
      const audioBlob = await response.blob();
      const audioObjectURL = URL.createObjectURL(audioBlob);
      const audioElement = new Audio(audioObjectURL);
      audioElement.dataset.id = id; // Ajoutez l'ID de la ligne comme attribut personnalisé

      // Démarrez la lecture de l'audio
      audioElement.play();
      currentAudioElement = audioElement; // Mettez à jour la référence
    } else {
      console.error("Échec de la récupération du fichier audio.");
    }
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la manipulation de l'audio :",
      error
    );
  }
};

const OrderGridlItemContent = ({ music, onCheckBox, playlist, phaseId }) => {
  //Affichage paroles
  const [openLyricsDialog, setOpenLyricsDialog] = useState(false);
  const [selectedMusicId, setSelectedMusicId] = useState(null);

  const handleOpenLyricsDialog = (musicId) => {
    if (window.innerWidth <= 768) {
      if (musicId) {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/musics/${musicId}/lyrics`, {
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("La récupération du PDF a échoué.");
            }
            return response.blob();
          })
          .then((pdfBlob) => {
            // Étape 2 : Création d'une URL pour le PDF
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Étape 3 : Ouvrir un nouvel onglet pour afficher le PDF
            const newTab = window.open("", "_blank");
            newTab.location.href = pdfUrl;
          })
          .catch((error) => {
            console.error("Une erreur est survenue :", error);
          });
      }
    } else {
      setSelectedMusicId(musicId);
      setOpenLyricsDialog(true);
    }
  };

  const handleCloseLyricsDialog = () => {
    setSelectedMusicId(null);
    setOpenLyricsDialog(false);
  };

  const isMusicInPlaylist = () => {
    //Retourne true si la musique est dans la playlist pour la phase correspondante à phaseId
    const index = playlist.findIndex(
      (musicInPlaylist) =>
        musicInPlaylist.music.id === music.id &&
        musicInPlaylist.phase.id === phaseId
    );

    return index !== -1;
  };

  return (
    <>
      <Grid
        item
        xs={4}
        sm={5}
        md={4}
        lg={3.5}
        className={styles["order-grid-item-content__music-menu"]}
      >
        <Radio
          onClick={() => onCheckBox(music.id)}
          checked={isMusicInPlaylist()}
        />
        <PlayArrowIcon
          onClick={() => handlePlayAudio(music.id)}
          className={styles["order-grid-item-content__music-menu_play-button"]}
        />
        <VisibilityIcon
          onClick={() => handleOpenLyricsDialog(music.id)}
          className={styles["order-grid-item-content__music-menu_play-button"]}
        ></VisibilityIcon>
      </Grid>
      <Grid
        item
        xs={8}
        sm={7}
        md={8}
        lg={8.5}
        className={styles["order-grid-item-content__music-name"]}
      >
        {music.name}
      </Grid>
      <LyricsDialog
        open={openLyricsDialog}
        onClose={handleCloseLyricsDialog}
        id={selectedMusicId}
      />
    </>
  );
};

export default OrderGridlItemContent;
