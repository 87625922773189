import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import { TextField, FormHelperText } from "@mui/material";
import styles from "./orderForm.module.css";

const regexMail = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
const regexPhone = /^((\+)33|0)[1-9](\d{2}){4}$/;

const OrderForm = ({ order, onOrderChange }) => {
  const [date] = useState(order.date);
  const [validationErrors, setValidationErrors] = useState({
    name: false,
    mail: false,
    phone: false,
    city: false,
    date: false,
  });

  const handleValueChange = (e) => {
    const { name, value } = e.target;

    onOrderChange({
      ...order,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    onOrderChange({ ...order, date: new Date(date).toISOString() });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const errors = { ...validationErrors };

    if (name === "name") {
      errors.name = !value;
    }

    if (name === "mail") {
      errors.mail = !value || !value.match(regexMail);
    }

    if (name === "phone") {
      errors.phone = !value || !value.match(regexPhone);
    }

    if (name === "city") {
      errors.city = !value;
    }

    if (name === "date") {
      errors.date = !value;
    }

    setValidationErrors(errors);
  };

  return (
    <div>
      <span>
        <TextField
          sx={{
            "backgroundColor": "#FCF8EF",
            "borderRadius": "5px",
          }}
          required
          id="name"
          name="name"
          label="Nom"
          fullWidth
          margin="normal"
          value={order.name}
          onChange={handleValueChange}
          onBlur={handleBlur}
        />
        {validationErrors.name && (
          <FormHelperText error>Ce champ est requis</FormHelperText>
        )}
      </span>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <span>
            <TextField
              sx={{
                "backgroundColor": "#FCF8EF",
                "borderRadius": "5px",
              }}
              required
              id="mail"
              name="mail"
              label="Email"
              fullWidth
              margin="normal"
              value={order.mail}
              onChange={handleValueChange}
              onBlur={handleBlur}
            />
            {validationErrors.mail && (
              <FormHelperText error>
                {validationErrors.mail && order.mail
                  ? "Format de l'adresse mail invalide (format cible : xxx@yyy.zz)"
                  : "Ce champ est requis"}
              </FormHelperText>
            )}
          </span>
        </Grid>
        <Grid item xs={12} sm={6}>
          <span>
            <TextField
              sx={{
                "backgroundColor": "#FCF8EF",
                "borderRadius": "5px",
              }}
              required
              id="phone"
              name="phone"
              label="Téléphone"
              fullWidth
              margin="normal"
              order={order.phone}
              onChange={handleValueChange}
              onBlur={handleBlur}
            />
            {validationErrors.phone && (
              <FormHelperText error>
                {validationErrors.phone && order.phone
                  ? "Format du numéro de téléphone invalide (format cible : 0xxxxxxxxx)"
                  : "Ce champ est requis"}
              </FormHelperText>
            )}
          </span>
        </Grid>

        <Grid item xs={12} sm={6}>
          <span>
            <TextField
              sx={{
                "backgroundColor": "#FCF8EF",
                "borderRadius": "5px",
              }}
              required
              id="city"
              name="city"
              label="Ville"
              fullWidth
              margin="normal"
              value={order.city}
              onChange={handleValueChange}
              onBlur={handleBlur}
            />

            {validationErrors.city && (
              <FormHelperText error>Ce champ est requis</FormHelperText>
            )}
          </span>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date"
              format="DD/MM/YYYY"
              value={dayjs(date)}
              onChange={handleDateChange}
              required
              fullWidth className={styles["order-form__date-picker"]}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <TextField
        sx={{
          "backgroundColor": "#FCF8EF",
          "borderRadius": "5px",
        }}
        label="Message"
        variant="outlined"
        name="comment"
        fullWidth
        multiline
        rows={6}
        margin="normal"
        value={order.comment}
        onChange={handleValueChange}
      />
    </div>
  );
};

export default OrderForm;
