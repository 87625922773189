import React, { useState, useEffect } from "react";

const CarouselItem = ({ id, name }) => {
  const [src, setSrc] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      // Vérifiez si l'image a déjà été téléchargée pour cet ID
      const cachedImage = sessionStorage.getItem(`image_${id}`);
      if (cachedImage) {
        setSrc(cachedImage);
        setIsLoading(false);
      } else {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/pictures/play/${id}`, {
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch image");
            }

            response.blob().then((blob) => {
              const imageUrl = URL.createObjectURL(blob);
              setSrc(imageUrl);
              setIsLoading(false);

              // Stockez l'image en cache pour une utilisation ultérieure
              sessionStorage.setItem(`image_${id}`, imageUrl);
            });
          })
          .catch((error) => {
            console.error("Error fetching image:", error);
            setIsLoading(false);
          });
      }
    }
  }, [id]);

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <img src={src} alt={name} />
      )}
    </div>
  );
};

export default CarouselItem;
