import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OrderGrid from "../../../component/orderGrid/orderGrid";
import OrderForm from "../../../component/orderForm/orderForm";
import styles from "./funeral.module.css";
import orderRestClient from "../../../client/order";

export default function WeddingOrder() {
  const navigate = useNavigate();
  const [order, setOrder] = useState({
    name: "",
    type: "Funeral",
    mail: "",
    phone: "",
    city: "",
    date: "",
    comment: "",
    playlist: [],
  });

  const handleOrderChange = (newOrderData) => {
    setOrder(newOrderData);
  };

  const addToPlaylist = (phaseId, musicId) => {
    const newPlaylist = [...order.playlist];

    // Vérifiez si il y a déjà la phase dans la playlist
    const phaseIndex = newPlaylist.findIndex(
      (play) => play.phase.id === phaseId
    );

    if (phaseIndex === -1) {
      // Si la phase n'est pas dans la playlist, on l'ajoute
      newPlaylist.push({
        music: {
          id: musicId,
        },
        phase: {
          id: phaseId,
        },
      });
    } else {
      // Si la phase est déjà dans la playlist, on remplace la musique
      newPlaylist[phaseIndex].music.id = musicId;
    }
    setOrder({ ...order, playlist: newPlaylist });
  };

  const submitOrder = async () => {
    // Validation des données
    if (order.name === "") {
      toast.error("Veuillez renseigner votre nom");
      return;
    }
    if (order.mail === "") {
      toast.error("Veuillez renseigner votre mail");
      return;
    }
    if (order.phone === "") {
      toast.error("Veuillez renseigner votre téléphone");
      return;
    }
    if (order.city === "") {
      toast.error("Veuillez renseigner votre ville");
      return;
    }
    if (order.date === "") {
      toast.error("Veuillez renseigner votre date");
      return;
    }

    console.log(order);

    await orderRestClient.createOrder(order).then((result) => {
      toast.success("Votre commande a bien été enregistrée");
      navigate("/");
    });
  };

  return (
    <main className="main">
      <div className="title">Funéraille</div>
      <div className="subTitle">
        Vous venez de perdre un parent ou un proche. Organiser une cérémonie de
        funérailles n’est pas toujours facile…
      </div>
      <div className={styles["presentation"]}>
        Pour vous aider à préparer votre célébration avec l’équipe funérailles
        de votre paroisse, vous trouverez ci-dessous une proposition de
        répertoire. J’accompagne vocalement les chants. Si vous désirez
        personnaliser la cérémonie avec d’autres chants/musiques, merci de me
        contacter. Vous pourrez écouter quelques extraits et choisir en ligne
        vos chants. Lors de notre rencontre nous finaliserons et validerons les
        choix.
      </div>

      <div className="subTitle">Informations</div>
      <OrderForm order={order} onOrderChange={handleOrderChange} />

      <div className="subTitle">Choisissez vos musiques</div>
      <div className={styles["wedding_music-choice"]}>
        <OrderGrid
          type={order.type}
          addToPlaylist={addToPlaylist}
          playlist={order.playlist}
        />
      </div>

      <div className="actionButton" id="validateButton" onClick={submitOrder}>
        Valider
      </div>
    </main>
  );
}
