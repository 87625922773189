import React from "react";
import styles from "./home.module.css";
import { useNavigate } from "react-router-dom";

export default function HomeAdmin() {
  const navigate = useNavigate();

  return (
    <main className="main">
      <span className="title">Page d'Administration</span>
      <div className={styles["tab-home-admin"]}>
      <div
          className="actionButton"
          id="connectionButton"
          style={{ margin: "20px" }}
          onClick={() => navigate("/admin/order")}
        >
          Gestion des Commandes
        </div>
        <div
          className="actionButton"
          id="connectionButton"
          style={{ margin: "20px" }}
          onClick={() => navigate("/admin/music")}
        >
          Gestion des Musiques
        </div>
        <div
          className="actionButton"
          id="connectionButton"
          style={{ margin: "20px" }}
          onClick={() => navigate("/admin/comment")}
        >
          Gestion des Commentaires
        </div>
        <div
          className="actionButton"
          id="connectionButton"
          style={{ margin: "20px" }}
          onClick={() => navigate("/admin/picture")}
        >
          Gestion des Images
        </div>
      </div>
    </main>
  );
}
