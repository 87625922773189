import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import styles from "./contact.module.css";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import sendContactMessage from '../../client/contact';


let flagMail = false;
let flagPhone = false;
let flagInvalidCarac = false;
const maxLengthContent = 400; //Nb max de caractères pour le message
const maxLengthSubject = 80; //Nb max de caractères pour l'objet du message
const maxLengthName = 30; //Nb max de caractères pour le nom / prénom
const regexMail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const regexPhone = /^0\d{9}$/

function MyForm() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        content: ''
    });

    const handleChange = (e) => {
        let { name, value } = e.target;
        switch (name) {
            case 'content':
                if (value.length >= maxLengthContent) {
                    value = value.slice(0, maxLengthContent);
                }
                break;
            case 'subject':
                if (value.length >= maxLengthSubject) {
                    value = value.slice(0, maxLengthSubject);
                }
                break;
            case 'firstName':
                if (value.length >= maxLengthName) {
                    value = value.slice(0, maxLengthName);
                }
                break;
            case 'lastName':
                if (value.length >= maxLengthName) {
                    value = value.slice(0, maxLengthName);
                }
                break;
            case 'email':
                if (value.match(regexMail)) {
                    flagMail = false;
                }
                else {
                    flagMail = true;
                }
                break;
            case 'phoneNumber':
                if (!value.match(/^\d*$/)) {
                    value = value.replace(/\D/g, '')
                    flagInvalidCarac = true
                }
                else {
                    flagInvalidCarac = false;
                }
                if (value.match(regexPhone)) {
                    flagPhone = false;
                }
                else {
                    flagPhone = true;
                }
                break;
            default:
                break;
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!flagPhone && !flagMail) {
            await sendContactMessage(formData);
            toast.success("Message envoyé avec succès !");
            navigate('/');
            return;
        }
        else {
            toast.error("Veuillez vérifier les champs en rouge");
        }
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        userSelect: 'none',
        width: 'fit-content',
        backgroundColor: '#A76151',
        fontSize: '15pt',
        color: 'white',
        borderRadius: '10px',
        textAlign: 'center',
        boxShadow: '5px 5px #8E624C',
        cursor: 'pointer',
        transition: 'all 0.2s',
        fontFamily: '"argestadisplay", sans-serif',
        marginTop: '15px',

        textTransform: 'none',

        '&:hover': {
            transform: 'scale(110%)',
            backgroundColor: '#A76151',
            boxShadow: '5px 5px #8E624C',
        },
        '&:active': {
            transform: 'translateY(2px)',
            backgroundColor: '#D8605A'
        }
    }));

    return (
        <main className="main">
            <span className="title">Contactez-nous !</span>
            <div className={styles["tab-contact"]}>
                <form onSubmit={handleSubmit} style={{ width: 'inherit' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                sx={{
                                    'background-color': '#FCF8EF',
                                    'border-radius': '5px',
                                }}
                                label="Prénom"
                                variant="outlined"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                            />
                            {formData.firstName.length >= maxLengthName && (
                                <FormHelperText error>
                                    Limite de caractères dépassée pour le prénom ({maxLengthName} car. max)
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                sx={{
                                    'background-color': '#FCF8EF',
                                    'border-radius': '5px'
                                }}
                                label="Nom"
                                variant="outlined"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                            />
                            {formData.lastName.length >= maxLengthName && (
                                <FormHelperText error>
                                    Limite de caractères dépassée pour le nom ({maxLengthName}  car. max)
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                sx={{
                                    'background-color': '#FCF8EF',
                                    'border-radius': '5px'
                                }}
                                label="E-mail"
                                variant="outlined"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                            />
                            {flagMail && (
                                <FormHelperText error>
                                    Format de l'adresse mail invalide (format cible : xxx@yyy.zz)
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                sx={{
                                    'background-color': '#FCF8EF',
                                    'border-radius': '5px'
                                }}
                                label="Numéro de téléphone"
                                variant="outlined"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                            {flagInvalidCarac && (
                                <FormHelperText error>
                                    Merci de ne taper QUE des chiffres
                                </FormHelperText>
                            )}
                            {flagPhone && (
                                <FormHelperText error>
                                    Format du numéro de téléphone (format cible : 0xxxxxxxxx)
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>


                    <TextField
                        sx={{
                            'background-color': '#FCF8EF',
                            'border-radius': '5px',
                        }}
                        label="Objet du contact"
                        variant="outlined"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        fullWidth
                        required
                        margin="normal"
                    />
                    {formData.subject.length >= maxLengthSubject && (
                        <FormHelperText error>
                            Limite de caractères dépassée pour l'objet ({maxLengthSubject}  car. max)
                        </FormHelperText>
                    )}
                    <TextField
                        sx={{
                            'background-color': '#FCF8EF',
                            'border-radius': '5px',
                        }}
                        label="Message"
                        variant="outlined"
                        name="content"
                        value={formData.content}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={6}
                        required
                        margin="normal"
                    />
                    {formData.content.length >= maxLengthContent && (
                        <FormHelperText error>
                            Le message dépasse la limite de caractères ({maxLengthContent} caractères maximum).
                        </FormHelperText>
                    )}
                    <ColorButton type="submit" variant="contained" color="primary" disableRipple disableTouchRipple disableFocusRipple>
                        Soumettre
                    </ColorButton>
                </form>
            </div>
        </main>
    );
}

export default MyForm;