import React, { useState, useEffect } from "react";
import orderRestClient from "../../../client/order";
import styles from "./order-management.module.css";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import OrderDialog from "../../../component/orderDialog/orderDialog";

function SimpleDialog(props) {
  const { onClose, onDelete, writerName, rowId, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleDelete = () => {
    onDelete(rowId);
  };

  return (
    <Dialog className={styles["delete-tab"]} onClose={handleClose} open={open}>
      <DialogTitle>
        Êtes-vous sûr de vouloir supprimer la commande de "{writerName}" ?
      </DialogTitle>
      <div className={styles["delete-tab__buttons"]}>
        <div
          className={"actionButton " + styles["delete-confirm-button"]}
          onClick={handleDelete}
        >
          Supprimer
        </div>
        <div
          className={"actionButton " + styles["delete-confirm-button"]}
          onClick={handleClose}
        >
          Retour
        </div>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ManageOrder() {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [open, setOpen] = useState(false);
  const [writerName, setwriterName] = useState("");
  const [rowId, setRowId] = useState(-1);

  const [openOrder, setOpenOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    orderRestClient
      .getOrders({
        name: undefined,
        type: undefined,
        startingDate: undefined,
        endingDate: undefined,
      })
      .then((orders) => {
        setRows(orders);
      });
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id, name) => () => {
    setwriterName(name);
    setRowId(id);
    setSelectedOrder(rows.find((row) => row.id === id));
    setOpenOrder(true);
  };

  const handleDeleteClick = (id, name) => () => {
    setwriterName(name);
    setRowId(id);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpenOrder(false);
    setOpen(false);
    setSelectedOrder(null);
  };

  const handleDeleteConfirmation = (id) => {
    setRows(rows.filter((row) => row.id !== id));
    setOpen(false);
    orderRestClient.deleteOrder(id);
  };

  const refreshRows = (order) => {
    console.log(order);
    setRows(
      rows.map((row) => {
        if (row.id === order.id) {
          return order;
        } else {
          return row;
        }
      })
    );
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 15,
      renderCell: (params) => {
        const date = new Date(params["value"]);
        var day = 0;
        if (date.getDate() + 1 <= 9) {
          day = "0" + date.getDate();
        } else {
          day = date.getDate();
        }

        var month = 0;
        if (date.getMonth() + 1 <= 9) {
          month = "0" + (date.getMonth() + 1);
        } else {
          month = date.getMonth() + 1;
        }

        const year = date.getFullYear();

        return (
          <div style={{ textAlign: "justify" }}>
            {day} / {month} / {year}
          </div>
        );
      },
    },
    { field: "name", headerName: "Auteur", flex: 15 },
    { field: "mail", headerName: "Mail", flex: 20 },
    { field: "phone", headerName: "Téléphone", flex: 15 },
    {
      field: "comment",
      headerName: "Commentaire",
      flex: 30,
      renderCell: (params) => {
        return <div style={{ textAlign: "justify" }}>{params.value}</div>;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 10,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(
              id,
              rows.find((row) => row.id === id).name
            )}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(
              id,
              rows.find((row) => row.id === id).name
            )}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <main className="main">
      <span className="title">Gestion des Commentaires</span>
      <div className={styles["tab-comment-management"]}>
        <DataGrid
          sx={{
            width: "100%",
            bgcolor: "#FCF8EF",
            fontFamily: "argestadisplay, sans-serif",
            fontSize: "12pt",
          }}
          getRowHeight={() => "auto"}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowEditStop={handleRowEditStop}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      </div>
      {openOrder ? (
        <OrderDialog
          open={openOrder}
          onClose={handleClose}
          refreshRows={refreshRows}
          order={selectedOrder}
        />
      ) : (
        ""
      )}

      <SimpleDialog
        open={open}
        onClose={handleClose}
        onDelete={handleDeleteConfirmation}
        writerName={writerName}
        rowId={rowId}
      />
    </main>
  );
}
