import FormData from 'form-data';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

async function uploadPicture({ name, type, file }) {
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('type', type);
    formData.append('file', file);

    const token = sessionStorage.getItem("token");

    const response = await fetch(`${API_BASE_URL}/pictures`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function getPictures({ name }) {
  try {

    let apiUrl = `${API_BASE_URL}/pictures`;
    const queryParams = {};

    if (name) {
      queryParams.name = name;
    }

    const queryString = new URLSearchParams(queryParams).toString();

    if (queryString) {
      apiUrl += `?${queryString}`;
    }

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function getPictureById({ pictureId }) {
  try {
    const response = await fetch(`${API_BASE_URL}/pictures/${pictureId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function updatePicture({ id, name, type }) {
  try {
    const response = await fetch(`${API_BASE_URL}/pictures`, {
      method: 'PUT',
      body: JSON.stringify({
        id,
        name,
        type,
      }),
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function deletePicture({ pictureId }) {
  try {
    const response = await fetch(`${API_BASE_URL}/pictures/${pictureId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "x-api-key": API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function getRandomPicture(){
  try {
    const response = await fetch(`${API_BASE_URL}/random/pictures`, {
      headers: {
        "x-api-key": API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

const pictureRestClient = {
  uploadPicture,
  getPictures,
  getPictureById,
  updatePicture,
  deletePicture,
  getRandomPicture
};

export default pictureRestClient;
