import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OrderGrid from "../../../component/orderGrid/orderGrid";
import OrderForm from "../../../component/orderForm/orderForm";
import styles from "./wedding.module.css";
import orderRestClient from "../../../client/order";

export default function WeddingOrder() {
  const navigate = useNavigate();
  const [order, setOrder] = useState({
    name: "",
    type: "Wedding",
    mail: "",
    phone: "",
    city: "",
    date: "",
    comment: "",
    playlist: [],
  });

  const handleOrderChange = (newOrderData) => {
    setOrder(newOrderData);
  };

  const addToPlaylist = (phaseId, musicId) => {
    const newPlaylist = [...order.playlist];

    // Vérifiez si il y a déjà la phase dans la playlist
    const phaseIndex = newPlaylist.findIndex(
      (play) => play.phase.id === phaseId
    );

    if (phaseIndex === -1) {
      // Si la phase n'est pas dans la playlist, on l'ajoute
      newPlaylist.push({
        music: {
          id: musicId,
        },
        phase: {
          id: phaseId,
        },
      });
    } else {
      // Si la phase est déjà dans la playlist, on remplace la musique
      newPlaylist[phaseIndex].music.id = musicId;
    }
    setOrder({ ...order, playlist: newPlaylist });
  };

  const submitOrder = async () => {
    // Validation des données
    if (order.name === "") {
      toast.error("Veuillez renseigner votre nom");
      return;
    }
    if (order.mail === "") {
      toast.error("Veuillez renseigner votre mail");
      return;
    }
    if (order.phone === "") {
      toast.error("Veuillez renseigner votre téléphone");
      return;
    }
    if (order.city === "") {
      toast.error("Veuillez renseigner votre ville");
      return;
    }
    if (order.date === "") {
      toast.error("Veuillez renseigner votre date");
      return;
    }

    console.log(order);

    await orderRestClient.createOrder(order).then((result) => {
      toast.success("Votre commande a bien été enregistrée");
      navigate("/");
    });
  };

  return (
    <main className="main">
      <div className="title">Mariage</div>
      <div className="subTitle">Vous avez choisi de vous dire « oui ».</div>
      <div className={styles["presentation"]}>
        Je peux accompagner musicalement votre cérémonie, accompagné par mon
        épouse au chant. Pour vous aider dans le choix des musiques et des
        chants, vous trouverez ci-dessous une proposition de répertoire pour un
        mariage en église avec des chants référencés. Vous pourrez aussi
        exprimer une demande particulière, chant ou musique, dans ce cas merci
        de me contacter. Un supplément de tarif pourra être demandé en fonction
        des répétitions supplémentaires nécessaires.
      </div>

      <div className="subTitle">Informations</div>
      <OrderForm order={order} onOrderChange={handleOrderChange} />

      <div className="subTitle">Choisissez vos musiques</div>
      <div className={styles["wedding_music-choice"]}>
        <OrderGrid
          type={order.type}
          addToPlaylist={addToPlaylist}
          playlist={order.playlist}
        />
      </div>

      <div className="actionButton" id="validateButton" onClick={submitOrder}>
        Valider
      </div>
    </main>
  );
}
